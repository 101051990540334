import React, { Fragment, PureComponent } from "react";
import AddToCartButton from "./AddToCartButton";
import { CURRENCY_FORMAT } from "../../Constants/AppConstants";
import Parser from 'html-react-parser';
import ReactImageFallback from "react-image-fallback";
import $ from "jquery";
import { Row, Col } from "react-bootstrap";
import SubDropdown from '../../Assets/images/affiliate_imges/sub_dropdown.svg'

class ProductsListWithFlavors extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            subscription: "no",
            modalId: "disclaimerModal" + this.props.product.product_id,
            productDetails : this.props.product
        }
    }

    showHideProductDetails = () => {
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }));
    }

    componentDidMount() {
        let product = this.props.product;
      //  this.firstMonthVariation(product.hasOwnProperty("flavors") ? product.flavors[0].id : null);

        this.setState({
            monthId: (product.hasOwnProperty("months") && product.months.length > 0) ? product.months[0].id : null,
         //   flavorId: product.hasOwnProperty("flavors") ? product.flavors[0].id : null,
        })

        // if (product.months.length > 0) {
        //     let monthFlabourId = product.months[0].id + "_" + product.flavors[0].id;
        //     this.variation(monthFlabourId);
        // }

        if (this.state.quantity) {
            this.setState({ quantity: parseInt(this.state.quantity) })
        } else {
            this.setState({ quantity: parseInt(product.quantity) })
        }
        const  monthNumber = product.months.length > 0 ? product.months[0].id : '';
        if(product.flavors.length > 0){
            this.activeFlavors(product,  monthNumber, 'All');
        } 
    }

    firstMonthVariation = (flavorId) => {
        const product = this.props.product;
        const concateVariationId = product.hasOwnProperty("first_month") ? (product.first_month + "_" + flavorId) : null;
        const variation = product.hasOwnProperty("variations") ? product.variations[concateVariationId] : null;

        let firstRegularPriceDisplay = false;
        let regular_price = 0;
        let sale_price = 0;
        if (parseFloat(variation.sale_price) > 0) {
            firstRegularPriceDisplay = true;
            regular_price = variation.regular_price;
            sale_price = variation.sale_price;
        } else {
            regular_price = variation.regular_price;
            sale_price = variation.regular_price;
        }

        this.setState({
            firstMonthVariationId: variation.hasOwnProperty("variation_id") ? variation.variation_id : null,
            firstRegularPrice: CURRENCY_FORMAT(regular_price),
            firstSalePrice: CURRENCY_FORMAT(sale_price),
            firstRegularPriceDisplay: firstRegularPriceDisplay,
            // AddToCart State for Cart
            itemCount: product.item_count,
            cartProductId: product.product_id,
            cartProductName: product.title,
            cartProductDescription: product.short_description,
            cartProductSKU: variation.sku,
            cartProductIsBundle: product.is_bundle,
            cartImage: product.thumb_image,
            cartFirstVariationId: variation.variation_id,
            cartFirstVariationName: variation.variation_name,
            cartFirstSalePrice: sale_price,
            cartFirstQuantity: variation.quantity,
            subscription: this.state.subscription,
            cartSubscriptionMsg: "Every 1 Month(s)",
            cartDiscountPrice: ((sale_price - (sale_price * product.subscription_save_percentage) / 100)),
            warehouse: variation?.warehouse,
        });
    }
    changeFirstMonthFlovour = (e) => {
        this.firstMonthVariation(e.target.value);
        this.state.firstMonthflavorId = e.target.value;
    }

    variation = (monthFlabourId) => {
        const product = this.props.product;
        const variation = product.hasOwnProperty("variations") ? product.variations[monthFlabourId] : null;

        let regularPriceDisplay = false;
        let regular_price = 0;
        let sale_price = 0;
        if (parseFloat(variation.sale_price) > 0) {
            regularPriceDisplay = true;
            regular_price = variation.regular_price;
            sale_price = variation.sale_price;
        } else {
            regular_price = variation.regular_price;
            sale_price = variation.regular_price;
        }

        this.setState({
            variationId: variation.hasOwnProperty("variation_id") ? variation.variation_id : null,
            regularPrice: CURRENCY_FORMAT(regular_price),
            salePrice: CURRENCY_FORMAT(sale_price),
            regularPriceDisplay: regularPriceDisplay,
            // AddToCart State for Cart
            itemCount: product.item_count,
            cartProductId: product.product_id,
            cartProductName: product.title,
            cartProductDescription: product.short_description,
            cartProductSKU: variation.sku,
            cartProductIsBundle: product.is_bundle,
            cartImage: product.thumb_image,
            cartVariationId: variation.variation_id,
            cartVariationName: variation.variation_name,
            cartSalePrice: sale_price,
            cartQuantity: variation.quantity,
            warehouse: variation?.warehouse,
        });
    }
    changeMonth = (e) => {
        this.setState({ monthId: e.target.value })
        if(this.state.activeFlavors !== undefined){
            this.activeFlavors(this.props.product,e.target.value, 'changeMonth');
        }

       // let monthFlabourId = e.target.value + "_" + this.state.flavorId;
       // this.variation(monthFlabourId);
    }

    activeFlavors = (product, monthId, all, ) => {
        if(all == 'All') {
            const  monthIdAsInt = parseInt(monthId)
            const variationsForFirstMonth = Object.values(product.variations)
                .filter(variation => variation.month === 1);
            const activeFirstFlavorIds = variationsForFirstMonth.map(variation => variation.flavor_id);
            const  updatedFirstFlavors = Object.values(product.flavors.filter(flavor => activeFirstFlavorIds.includes(flavor.id)));

            let defaultFirstFlavor = null;
            if (updatedFirstFlavors.length > 0) {
                defaultFirstFlavor = updatedFirstFlavors[0];
            }
           
            if (defaultFirstFlavor) {
                this.setState({ firstMonthflavorId: defaultFirstFlavor.id });
                this.setState({ firstMonthActiveFlavors : updatedFirstFlavors})
            }
          
            if (defaultFirstFlavor) {
                this.firstMonthVariation(updatedFirstFlavors[0].id);
            }
            
            const variationsForMonth = Object.values(product.variations)
                .filter(variation => variation.month === monthIdAsInt);
            const activeFlavorIds = variationsForMonth.map(variation => variation.flavor_id);
            const  updatedFlavors = Object.values(product.flavors.filter(flavor => activeFlavorIds.includes(flavor.id)));
         
            let defaultFlavor = null;
            if (updatedFlavors.length > 0) {
                defaultFlavor = updatedFlavors[0];
            }
            if (defaultFlavor) {
                this.setState({ flavorId: defaultFlavor.id });
                this.setState({ activeFlavors : updatedFlavors})
            }
            if (defaultFlavor) {
                let variationKey = monthId + "_" + defaultFlavor.id;
                this.variation(variationKey);
            }

        } else if (all === 'changeMonth') {
            const monthIdAsInt = parseInt(monthId);
            const variationsForMonth = Object.values(product.variations)
                .filter(variation => variation.month === monthIdAsInt);
            const activeFlavorIds = variationsForMonth.map(variation => variation.flavor_id);
        
            const updatedFlavors = product.flavors
                .filter(flavor => activeFlavorIds.includes(flavor.id));
        
            let defaultFlavor = null;
            if (updatedFlavors.length > 0) {
                defaultFlavor = updatedFlavors.find(flavor => flavor.id === this.state.flavorId) || updatedFlavors[0];
            }
            if (defaultFlavor) {
                const defaultFlavorIndex = updatedFlavors.findIndex(flavor => flavor.id === defaultFlavor.id);
                if (defaultFlavorIndex !== -1) {
                    updatedFlavors.unshift(updatedFlavors.splice(defaultFlavorIndex, 1)[0]);
                }
                this.setState({ flavorId: defaultFlavor.id });
                this.setState({ activeFlavors: updatedFlavors });
                let variationKey = monthId + "_" + defaultFlavor.id;
                this.variation(variationKey);
            }
        }
    }
    changeFlavor = (e) => {
        this.setState({ flavorId: e.target.value })
        let monthFlabourId = this.state.monthId + "_" + e.target.value;
        this.variation(monthFlabourId);
    }

    isSubscription = (e) => {
        const subscription = e.target.value;
    
        if (subscription == 'yes') {
            this.setState({ subscription: "no" }, () => {
                this.filterFlavors(subscription);
            });
        } else {
            this.setState({ subscription: "yes" }, () => {
                const selectedFlavorId = this.state.firstMonthflavorId;
                this.filterFlavors(subscription, selectedFlavorId);
            })
            // $(`#${this.state.modalId}`).modal({
            //     backdrop: 'static',
            //     keyboard: false,
            // });
            
        }
    }
    
    subscribeModal = (e) => {
        $(`#${this.state.modalId}`).modal({
            backdrop: 'static',
            keyboard: false,
        });
    }
    
    filterFlavors = (subscription, selectedFlavorId) => {
        if (subscription === 'no') {
            const variationsWithSubscriptionStatus0 = Object.values(this.props.product.variations)
                .filter(variation => variation.subscription_status === "0");
    
            const flavorIdsToRemove = variationsWithSubscriptionStatus0.map(variation => variation.flavor_id);
            const updatedFlavors = this.state.firstMonthActiveFlavors.filter(flavor => !flavorIdsToRemove.includes(flavor.id));
    
            // Find the index of the selected flavor
            const selectedIndex = updatedFlavors.findIndex(flavor => flavor.id === selectedFlavorId);
    
            // If selected flavor is not found or is inactive, find the first active flavor
            let selectedFlavor = selectedIndex !== -1 ? updatedFlavors[selectedIndex] : null;
           if (!selectedFlavor) {
                $(`#${'alertModel1'}`).modal({
                    backdrop: 'static',
                    keyboard: false,
                });
                const firstActiveFlavor = updatedFlavors.find(flavor => !flavorIdsToRemove.includes(flavor.id));
                if (firstActiveFlavor) {
                    selectedFlavor = firstActiveFlavor;
                }
            }else {
                this.subscribeModal();
            }
    
            // Remove the selected flavor from the array before rearranging
            const flavorsWithoutSelected = selectedFlavor
                ? updatedFlavors.filter(flavor => flavor.id !== selectedFlavor.id)
                : updatedFlavors;
    
            // Rearrange the flavors array
            const rearrangedFlavors = selectedFlavor
                ? [selectedFlavor, ...flavorsWithoutSelected]
                : flavorsWithoutSelected;

                if(rearrangedFlavors.length > 0){
                   this.setState({ firstMonthActiveFlavors : rearrangedFlavors })
                   this.setState({ firstMonthflavorId : rearrangedFlavors[0].id }) 
                   this.firstMonthVariation(rearrangedFlavors[0].id);
                
                } else {
                    this.setState({ firstMonthActiveFlavors : undefined });
                }
        } else {
            const variationsForFirstMonth = Object.values(this.props.product.variations)
            .filter(variation => variation.month === 1);
            const activeFirstFlavorIds = variationsForFirstMonth.map(variation => variation.flavor_id);
            const  updatedFirstFlavors = this.props.product.flavors.filter(flavor => activeFirstFlavorIds.includes(flavor.id));
            this.setState({ firstMonthActiveFlavors : updatedFirstFlavors });
        }
    }
    

    quantityIncrement = (e) => {
        this.setState({ quantity: parseInt(Number(this.state.quantity) + 1) })
    }

    quantityDecrement = (e) => {
        this.setState({ quantity: parseInt(Number(this.state.quantity) - 1) });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const product = this.state.productDetails;
        const { flavorId } = this.state;
        const {firstMonthflavorId} = this.state
        const activeMonthFlavors = this.state.activeFlavors
        const ActiveFirstMonthFlavors = this.state.firstMonthActiveFlavors
        return (
            <Fragment>
                <div className="order_pro_list white_bg">
                    <Row>
                        <Col md={12}>
                            <div className="d-flex order_pro_left_det">
                                <div className="order_pro_img">
                                    <ReactImageFallback
                                        src={product.hasOwnProperty('list_image') ? product.list_image : null}
                                        fallbackImage={require('../../Assets/images/preloader.gif')}
                                        initialImage={require('../../Assets/images/preloader.gif')}
                                        alt={product.hasOwnProperty('title') ? product.title : null}
                                        className="" />
                                </div>
                                <div className="order_pro_det pl_16">
                                    <h2 className="text_black d-flex m-0 font_20 let_spa_2 fw_el_bold"> {product.hasOwnProperty('title') ? product.title : null} </h2>
                                    <h3 className="text_black d-flex mb_10 font_16 let_spa_2 fw_ar_reg pro_des_aff"> {product.hasOwnProperty('short_description') ? Parser(product.short_description) : null}</h3>
                                    <div className="row m-0">
                                        <div className="col-md-8 p-0">
                                            <div className="d-flex align-items-center">

                                                {
                                                    (product.subscription === "yes") ?
                                                        <Fragment>
                                                            <label className="subscri_blk mr_19 d-flex align-items-center checkbox_label mb_0 radio_label" htmlFor="">
                                                                <div className="sub_lft position-relative">
                                                                    <input onChange={this.isSubscription} value={this.state.subscription} type="checkbox" name="" checked={(this.state.subscription === 'yes') ? true : false} className="form-check-input" id="" />
                                                                    <span className="checbox"></span>
                                                                </div>
                                                                <div className="sub_rgt pl_16">
                                                                    <div className="d-flex align-items-center mb_5">
                                                                        <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0">Subscribe and Save {product.hasOwnProperty('subscription_save_percentage') ? product.subscription_save_percentage : null} %</h3>
                                                                        {/* <img src={SubDropdown} alt="SubDropdown" className="ml_19" /> */}
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="d-block font_14 text_black fw_ar_bold let_spa_2">{CURRENCY_FORMAT(this.state.cartDiscountPrice)}</span>
                                                                        {/* <span className="d-block font_14 text_black fw_ar_bold let_spa_2 pl_6">$139.00</span> */}
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </Fragment>
                                                        : null
                                                }
                                                {(ActiveFirstMonthFlavors !== undefined)? (
                                                <div className="subscri_blk choose_fla_blk">
                                                    <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0 ">Choose Flavor</h3>
                                                    <select onChange={this.changeFirstMonthFlovour} name="flovour" className="w-100 select_flavour font_16 let_spa_2 text-black fw_ar_bold" value={firstMonthflavorId}>
                                                        {
                                                            (ActiveFirstMonthFlavors !== undefined ) ? 
                                                                ActiveFirstMonthFlavors.map(function (flavor, key) {
                                                                    return (
                                                                        <option key={key} value={flavor.hasOwnProperty('id') ? flavor.id : null}>{flavor.hasOwnProperty('value') ? flavor.value : null}</option>
                                                                    )
                                                            }.bind(this)) : null 
                                                        }
                                                    </select>
                                                </div>
                                            ) : (
                                                <div className="subscri_blk choose_fla_blk">
                                                    <h3 className="font_16 text_black fw_ar_bold mb_8">Currently No Flavors Active for Subscription!</h3>
                                                </div>
                                            )}
                                                
                                            </div>
                                        </div>
                                        <div className="col-md-4 p-0 pro_qty_cat_blk">
                                            <Col md={12} className="p-0 order_pro_btn d-flex flex-column align-items-end justify-content-between">
                                                <div className="d-flex align-items-center justify-content-end mb_15">
                                                    {
                                                        (this.state.firstRegularPrice != '$0.00 USD') ?
                                                            <span className="font_16 d-block items_text let_spa_2 fw_ar_reg line_through">{this.state.firstRegularPrice}</span>
                                                            : ""
                                                    }
                                                    {(this.state.subscription == 'no') ?
                                                        <span className='font_16 text_black fw_ar_reg d-block pl_10'>{this.state.firstSalePrice}</span>
                                                        : <span className='font_16 text_black fw_ar_reg d-block pl_10'>{CURRENCY_FORMAT(this.state.cartDiscountPrice)}</span>
                                                    }
                                                </div>
                                                { (ActiveFirstMonthFlavors !== undefined) ?
                                                    <div className="quan_cart_blk d-flex w-100 justify-content-end">
                                                        <AddToCartButton firstMonth="yes" cl cartState={this.state} updateError={this.props.updateError} />
                                                    </div>
                                                : 
                                                    <div className="quan_cart_blk d-flex w-100 justify-content-end">
                                                        <input name="addToCart" value="Add To Cart" className="cart_add_product_btn stockout-btn disable" type="button" />
                                                    </div>
                                                }
                                            </Col>
                                        </div>
                                    </div>
                                    {
                                        (product.months.length <= 0) ?
                                            "" :
                                            <Fragment>
                                                <div className="row m-0 subscribtion_mont_row">
                                                    <div className="col-md-8 p-0">
                                                        <div className="d-flex align-items-center">

                                                            {
                                                                (product.subscription == "yes") ?
                                                                    <Fragment>
                                                                        <label className="subscri_blk mr_19 d-flex align-items-center checkbox_label mb_0 radio_label" htmlFor="">
                                                                            {/* <div className="sub_lft position-relative">
                                                                                <input onChange={this.isSubscription} value={this.state.subscription} type="checkbox" name="" checked={(this.state.subscription == 'yes') ? true : false} className="form-check-input" id="" />
                                                                                <span className="checbox"></span>
                                                                            </div> */}
                                                                            <div className="sub_rgt w-100">
                                                                                <div className="subscri_blk choose_fla_blk p-0">
                                                                                    <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0 ">Choose Month</h3>
                                                                                    <select onChange={this.changeMonth} name="month" className="w-100 select_flavour font_16 let_spa_2 text-black fw_ar_bold">
                                                                                        {
                                                                                            (product.months.length <= 0) ? null :
                                                                                                product.months.map(function (month, key) {
                                                                                                    return (
                                                                                                        <option key={key} value={month.hasOwnProperty('id') ? month.id : null}>{month.hasOwnProperty('value') ? month.value : null}</option>
                                                                                                    )
                                                                                                }.bind(this))
                                                                                        }
                                                                                    </select>
                                                                                    {/* <img src={SubDropdown} alt="SubDropdown" className="ml_19" /> */}
                                                                                </div>
                                                                                {/* <div className="d-flex align-items-center">
                                                                                    <span className="d-block font_14 text_black fw_ar_bold let_spa_2">{CURRENCY_FORMAT(this.state.cartDiscountPrice)}</span> */}
                                                                                {/* <span className="d-block font_14 text_black fw_ar_bold let_spa_2 pl_6">$139.00</span> */}
                                                                                {/* </div> */}
                                                                            </div>
                                                                        </label>
                                                                    </Fragment>
                                                                    : null
                                                            }
                                                            <div className="subscri_blk choose_fla_blk">
                                                                <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0 ">Choose Flavor</h3>
                                                                <select onChange={this.changeFlavor} name="flovour" className="w-100 select_flavour font_16 let_spa_2 text-black fw_ar_bold" value={flavorId}>
                                                                    {
                                                                        (activeMonthFlavors !== undefined) ? 
                                                                            activeMonthFlavors.map(function (flavor, key) {
                                                                                return (
                                                                                    <option key={key} value={flavor.hasOwnProperty('id') ? flavor.id : null}>{flavor.hasOwnProperty('value') ? flavor.value : null}</option>
                                                                                )
                                                                        }.bind(this)) : null
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 p-0 pro_qty_cat_blk">
                                                        <Col md={12} className="p-0 order_pro_btn d-flex flex-column align-items-end justify-content-between">
                                                            <div className="d-flex align-items-center justify-content-end mb_15">
                                                                {
                                                                    (this.state.regularPriceDisplay) ?
                                                                        <span className="font_16 d-blocks items_text let_spa_2 fw_ar_reg line_through">{this.state.regularPrice}</span>
                                                                        : ""
                                                                }
                                                                <span className="font_16 d-block text_black let_spa_2 fw_ar_reg pl_16">{this.state.salePrice}</span>
                                                            </div>
                                                            <div className="quan_cart_blk d-flex w-100 justify-content-end">
                                                                <AddToCartButton cartState={this.state} updateError={this.props.updateError} />
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </Fragment>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* subscription disclaimer modal start  */}
                <div className="modal fade" id={this.state.modalId} tabIndex="-1" role="dialog" aria-labelledby="disclaimerModal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header cus-modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body montserrat">
                                <p className="modal-body-content">
                                    By selecting "Subscribe & Save" I understand that I can cancel at any time by contacting the PL support team or through my affiliate dashboard by navigating to <strong>Orders → Active Subscriptions → Sub ID → Cancel Subscription</strong>.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={(e) => this.setState({ subscription: 'yes' })} type="button" className="cus_button" data-dismiss="modal">I Agree</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* subscription disclaimer modal end  */}
                {/* subscription Inactive modal start  */}
                <div className="modal fade" id={'alertModel1'} tabIndex="-1" role="dialog" aria-labelledby="alertModel1" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header cus-modal-header">
                                <button onClick={(e) => this.subscribeModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body montserrat">
                                <p className="modal-body-content">
                                    Subscribe and save only available on select flavors.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={(e) => this.subscribeModal()}  value={'alertModel1'} type="button" className="cus_button" data-dismiss="modal">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* subscription Inactive modal end  */}

            </Fragment>
        );
    }
}

export default ProductsListWithFlavors;
