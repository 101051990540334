import React, { PureComponent, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from "jquery";
import { GET_STORAGE } from '../../../Constants/AppConstants';
import DashboardIcon from '../../../Assets/images/customer_images/dashboard_icon.svg'
import DashboardIconActive from '../../../Assets/images/customer_images/dashboard_icon_active.svg'
import OrderIconActive from '../../../Assets/images/customer_images/orders_icon_active.svg'
import SubscriptionIconActive from '../../../Assets/images/customer_images/subscription_icon_active.svg'
import AddressIconActive from '../../../Assets/images/customer_images/address_icon_active.svg'
import AccountIconActive from '../../../Assets/images/customer_images/account_detail_icon_active.svg'
import CardIconActive from '../../../Assets/images/customer_images/my_card_icon_active.svg'
import OrderIcon from '../../../Assets/images/customer_images/orders_icon.svg'
import SubscriptionIcon from '../../../Assets/images/customer_images/subscription_icon.svg'
import AddressIcon from '../../../Assets/images/customer_images/address_icon.svg'
import AccountIcon from '../../../Assets/images/customer_images/account_detail_icon.svg'
import CardIcon from '../../../Assets/images/customer_images/my_card_icon.svg'
import MenuDropdown from '../../../Assets/images/affiliate_imges/affi_dropdown.svg'
import PaymentIcon from '../../../Assets/images/affiliate_imges/payment_icon.svg'
import TaxIcon from '../../../Assets/images/affiliate_imges/tax_icon.svg'
import TaxActiveIcon from '../../../Assets/images/affiliate_imges/tax_active_icon.svg'

class DistSideMenuLink extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpen: false,
            is_dropdown: false,
            is_dropdown_show: false,
            is_dropdown_open: false,
            is_show: false,
            show_dropdown: false,
            activeTab: localStorage.getItem("activeTab") || "overview", // Get from localStorage
        

        };
    }

    componentDidMount() {
        const savedTab = localStorage.getItem("activeTab");
        if (savedTab) {
            this.setState({ activeTab: savedTab });
        }
    }
    handleDropdownToggle = () => {
        this.setState((prevState) => ({
            isDropdownOpen: !prevState.isDropdownOpen,
            is_dropdown: false,
            is_dropdown_show: false,
            is_dropdown_open: false,
            is_show: false,
            show_dropdown: false
        }));
    };
    handleDropdownToggle1 = () => {
        this.setState((prevState) => ({
            is_dropdown_open: !prevState.is_dropdown_open,
            isDropdownOpen: false,
            is_dropdown: false,
            is_dropdown_show: false,
            is_show: false,
            show_dropdown: false
        }));
    };
    handleDropdownToggle2 = () => {
        this.setState((prevState) => ({
            is_dropdown: !prevState.is_dropdown,
            is_dropdown_show: false,
            is_dropdown_open: false,
            isDropdownOpen: false,
            is_show: false,
            show_dropdown: false
        }));
    };
    handleDropdownToggle3 = () => {
        this.setState((prevState) => ({
            is_dropdown_show: !prevState.is_dropdown_show,
            is_dropdown_open: false,
            isDropdownOpen: false,
            is_dropdown: false,
            is_show: false,
            show_dropdown: false
        }));
    };
    handleDropdownToggle4 = () => {
        this.setState((prevState) => ({
            is_show: !prevState.is_show,
            is_dropdown_open: false,
            isDropdownOpen: false,
            is_dropdown: false,
            is_dropdown_show: false,
            show_dropdown: false
        }));
    };
    handleDropdownToggle5 = () => {
        this.setState((prevState) => ({
            show_dropdown: !prevState.show_dropdown,
            is_dropdown_open: false,
            isDropdownOpen: false,
            is_dropdown: false,
            is_dropdown_show: false,
            is_show: false
        }));
    };
    handleTabClick = (tabName) => {
        this.setState({ activeTab: tabName });
        localStorage.setItem("activeTab", tabName); // Save to localStorage
    }
    render() {
        const { user } = this.props.auth;
        const { activeTab } = this.state;
        console.log('User data', this.props.auth);

        let settings = null;
        if (GET_STORAGE("settings")) {
            settings = JSON.parse(GET_STORAGE("settings"));
        }

        let meal_menu_active = false;
        if (settings && settings.meal_menu_public == "yes") {
            meal_menu_active = true;
        } else {
            if (this.props) {
                if (this.props.auth) {
                    if (this.props.auth.user) {
                        if (this.props.auth.user.meal_menu_activated) {
                            meal_menu_active = true;
                        }
                    }
                }
            }
        }

        let commission_payout_settings_menu_show = 'no';
        if (settings && settings.hasOwnProperty('commission_payout_settings_menu_show')) {
            commission_payout_settings_menu_show = settings.commission_payout_settings_menu_show;
        }

        return (
            <Fragment>
                <li>
                    <NavLink to="/my-account" activeClassName="active d-flex align-items-center font_16 fw_ar_bold" exact>
                        <img src={DashboardIcon} alt="dashboard_icon" className="inactive_icon" />
                        <img src={DashboardIconActive} alt="dashboard_icon" className="active_icon" />
                        Dashboard
                    </NavLink>
                </li>
                <li className={`dropdown ${this.state.isDropdownOpen ? 'open' : ''}`}>
                    <a id="dropdownMenuButton" className="menu_item d-flex align-items-center justify-content-between" href="#" onClick={this.handleDropdownToggle}>
                        <div className="d-flex align-items-center">
                            <img src={OrderIcon} alt="orderIcon" className="" />
                            <span className="d-block pl_9">Orders</span>
                        </div>
                        <img src={MenuDropdown} alt="dropDown" className="drop_icon" />
                    </a>
                    <div className={`dropdown-menu position-static w-100 afi_dah_show ${this.state.isDropdownOpen ? 'show' : '' }`}>
                        <ul>
                            <li>
                                <NavLink to="/my-account/view-order" activeClassName={activeTab === "overview" ? "active d-flex align-items-center font_16 fw_ar_bold" : ""} onClick={() => this.handleTabClick("overview")}>Overview</NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/subscription-order" activeClassName={activeTab === "subscriptionOrder" ? "active d-flex align-items-center font_16 fw_ar_bold" : ""} onClick={() => this.handleTabClick("subscriptionOrder")}>Subscription Orders</NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/subscription-order/active-subscription" activeClassName={activeTab === "activeSubscription" ? "active d-flex align-items-center font_16 fw_ar_bold" : ""} onClick={() => this.handleTabClick("activeSubscription")}>Active Subscriptions</NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/subscription-order/onhold-subscription" activeClassName={activeTab === "onHoldSubscription" ? "active d-flex align-items-center font_16 fw_ar_bold" : ""} onClick={() => this.handleTabClick("onHoldSubscription")}>On-Hold Subscriptions</NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/canceled-subscription" activeClassName={activeTab === "canceledSubscription" ? "active d-flex align-items-center font_16 fw_ar_bold" : ""} onClick={() => this.handleTabClick("canceledSubscription")}>Canceled Subscription</NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
               
                <li className={`dropdown ${this.state.is_dropdown ? 'open' : ''}`}>
                    <a id="dropdownMenuButton2" aria-expanded="false" className="menu_item d-flex align-items-center justify-content-between" href="#" onClick={this.handleDropdownToggle2}>
                        <div className="d-flex align-items-center">
                            <img src={PaymentIcon} alt="SubscriptionIcon" className='' />
                            <span className='d-block pl_9'>Commission/Refer Links</span>
                        </div>
                        <img src={MenuDropdown} alt="dropDown" className='drop_icon' />
                    </a>
                    <div className={`dropdown-menu position-static w-100 afi_dah_show ${this.state.is_dropdown ? 'show' : '' }`}>
                        <ul>
                            <li>
                                <NavLink to="/my-account/commission-payout" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"> Commission Earned</NavLink>
                            </li>
                            {
                                meal_menu_active ?
                                    <li>
                                        <NavLink to="/my-account/commission-coupon" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"> Meal Commission Coupon</NavLink>
                                    </li>
                                    : ''
                            }

                            <li>
                                <NavLink to="/my-account/product-refer-link" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"> Refer Link</NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/quiz-refer-link" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"> Quiz Refer Link</NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/zero-out-commission" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"> Zeroed out Comm.</NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/payment-received" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"> Payment Received</NavLink>
                            </li>
                            {
                                (commission_payout_settings_menu_show == 'yes') ?
                                    <li>
                                        <NavLink to="/my-account/commission-payout-method" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"> Commission Payout Method</NavLink>
                                    </li>
                                    : ""
                            }
                        </ul>
                    </div>
                </li>
                <li className={`dropdown ${this.state.show_dropdown ? 'open' : ''}`}>
                    <a id="dropdownMenuButton3" className="menu_item d-flex align-items-center justify-content-between" href="#" onClick={this.handleDropdownToggle5}>
                        <div className="d-flex align-items-center">
                            <img src={AccountIcon} alt="account icon" className='' />
                            <span className='d-block pl_9'>Team Member</span>
                        </div>
                        <img src={MenuDropdown} alt="dropDown" className='drop_icon' />
                    </a>
                    <div className={`dropdown-menu position-static w-100 afi_dah_show ${this.state.show_dropdown ? 'show' : '' }`}>
                        <ul>
                        {
                            user.sales_agent_feature === "enable" ?
                                <Fragment>
                                    <li>
                                        <NavLink to="/my-account/manage-representative" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"> Team Member</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/my-account/member-commission" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"> Member Commission</NavLink>
                                    </li>
                                </Fragment>
                                :
                                null
                        }
                        </ul>
                    </div>
                </li>
                <li className={`dropdown ${this.state.is_dropdown_open ? 'open' : ''}`}>
                    <a id="dropdownMenuButton1" className="menu_item d-flex align-items-center justify-content-between" href="#" onClick={this.handleDropdownToggle1}>
                        <div className="d-flex align-items-center">
                            <img src={SubscriptionIcon} alt="SubscriptionIcon" className='' />
                            <span className='d-block pl_9'>Reports</span>
                        </div>
                        <img src={MenuDropdown} alt="dropDown" className='drop_icon' />
                    </a>
                    <div className={`dropdown-menu position-static w-100 afi_dah_show ${this.state.is_dropdown_open ? 'show' : '' }`}>
                        <ul>
                            <li> <NavLink to="/my-account/statistics-reports" activeClassName="active d-flex align-items-center font_16 fw_ar_bold">Statistics Reports</NavLink></li>
                            <li><NavLink to="/my-account/leaderboard" activeClassName="active d-flex align-items-center font_16 fw_ar_bold">Leaderboard</NavLink></li>
                        </ul>
                    </div>
                </li>

                <li className={`dropdown ${this.state.is_show ? 'open' : ''}`} onClick={this.handleDropdownToggle4}>
                    <NavLink to="/my-account/w-9-form-information" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"><img src={TaxIcon} alt="tax_icon" className='inactive_icon' /> <img src={TaxActiveIcon} alt="tax active icon" className='active_icon' />Tax Form Information</NavLink>
                </li>
                <li className={`dropdown ${this.state.is_dropdown_show ? 'open' : ''}`}>
                    <a id="dropdownMenuButton3" className="menu_item d-flex align-items-center justify-content-between" href="#" onClick={this.handleDropdownToggle3}>
                        <div className="d-flex align-items-center">
                            <img src={AccountIcon} alt="account icon" className='' />
                            <span className='d-block pl_9'>Account Details</span>
                        </div>
                        <img src={MenuDropdown} alt="dropDown" className='drop_icon' />
                    </a>
                    <div className={`dropdown-menu position-static w-100 afi_dah_show ${this.state.is_dropdown_show ? 'show' : '' }`}>
                        <ul>
                            <li>
                                <NavLink to="/my-account/edit-account" activeClassName="active d-flex align-items-center font_16 fw_ar_bold"> Edit Account</NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/settings" activeClassName="active d-flex align-items-center font_16 fw_ar_bold">Settings</NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/paypal-account" activeClassName="active d-flex align-items-center font_16 fw_ar_bold">PayPal Email</NavLink>
                            </li>
                        </ul>
                    </div>
                </li>

            </Fragment>
        );
    }
}

DistSideMenuLink.propTypes = {
    auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default withRouter(connect(mapStateToProps)(DistSideMenuLink));
