import React, { Component, Fragment } from 'react';
import { AJAX_REQUEST, GET_STORAGE } from "../../../Constants/AppConstants";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class QuizReferLink extends Component {
    constructor(props) {
        super(props);
        let settings = null;
        if (GET_STORAGE("settings")) {
            settings = JSON.parse(GET_STORAGE("settings"));
        }

        this.state = {
            error_meg: '',
            loading: true,
        }
        document.title = "Quiz Refer Link -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.getUserInfo();
    }

    getUserInfo = () => {
        AJAX_REQUEST("POST", "distributor/dashboard", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    userInfo: results.response.data,
                    affiliate_panel_video_embed: results.response.data.affiliate_panel_video_embed,
                    loading: false
                });
            } else {
                this.setState({
                    errorMeg: results.response.message,
                    loading: false
                })
            }
        });
    }

    render() {

        let settings = null;
        if (GET_STORAGE("settings")) {
            settings = JSON.parse(GET_STORAGE("settings"));
        }

        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <Fragment>
                            <div className="woocommerce-MyAccount-content inner_content member_tab_wrapper">
                                <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold">Quiz Refer Link</h2>
                                <h3 className="font_16 mb_15 text_black fw_ar_reg">Affiliate Dashboard &gt; Payment &gt; Quiz Refer Link</h3>

                                <div className="tab-content p-0" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div className="table-responsive">
                                            <table className="w-100 subscribtion_table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Quiz Refer Link</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Quiz
                                                        </td>
                                                        <td>
                                                            <a className="word_break font_16 fw_ar_reg text_underline text_black" href={this.state.userInfo.hasOwnProperty('affiliate_quiz_url') ? this.state.userInfo.affiliate_quiz_url : ''} target="_blank">{this.state.userInfo.hasOwnProperty('affiliate_quiz_url') ? this.state.userInfo.affiliate_quiz_url: ''}</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </Fragment>
                }
            </Fragment>
        );
    }
}

QuizReferLink.propTypes = {
    auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(QuizReferLink);